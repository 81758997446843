import React from "react";
import FooterContentful from "./FooterContentful";
import "./style.css";

const Footer = () => {
  return (
    <div className="flex flex-col items-center px-6 pt-4 md:pt-24">
      <FooterContentful />
    </div>
  );
};

export default Footer;
