import React from "react";
import * as strings from "../../../utils/staticContent";

const FooterContentful = () => {
  return (
    <footer className="max-w-7xl flex flex-col w-full items-center pt-44 text-center">
      <div className="flex flex-col items-center">
        <div className="flex items-center flex-col">
          <div className="mb-4 text-beige center">
            For any support:{" "}
            <a className="leading-9 underline" href={strings.MAIL_TO_LINK}>
              {strings.CONTACT_US_EMAIL}
            </a>
          </div>
        </div>
        <span className="mb-6 text-beige">
          © Copyright 2020-2022. All Rights Reserved
        </span>
      </div>
    </footer>
  );
};

export default FooterContentful;
