import { Bestseller, Testimonial } from "./types";

export const MAIN_TITLE = "Santa's operations have closed.";
export const SANTA_SERVICE_SHORT_DESCRIPTION =
  "We thank all our customers for their loyalty and are grateful for the journey.";
export const PRESS_REFERENCES = "As Featured In:";
export const PERSONAL_STORE_TITLE = "That’s a wrap.";
export const PERSONAL_STORE_DESCRIPTION = [
  "Santa is the modern destination for gifting.",
  "Through our app, we facilitate the discovery of premium products curated from top national and global brands as well as local artisans and small businesses, including home & kitchen, beauty, wellness, fashion, tech, kids and more.",
];
export const HOW_IT_WORKS_TITLE = "How It Works";
export const HOW_IT_WORKS_CONTENT = [
  ["01", "Download the app and swipe through our curated edit."],
  ["02", "Choose the things you’d love to gift or get."],
  ["03", "Place your order and get it delivered as soon as next day."],
];
export const AVAILABLE_AT =
  "In our opinion, every occasion is an occasion for gifting.";
export const LOCATIONS = ["Birthdays", "Anniversaries", "Just Because"];
export const LOCATIONS_SEPARATOR = "|";
export const BESTSELLERS_TITLE = "Our Bestsellers";
export const BESTSELLERS_SUBTITLE =
  "Swipe to browse our customers’ most favorite gifts.";
export const CUSTOMERS_FEEDBACK_TITLE = "From Our Customers";
export const CONTACT_US_TITLE = "Contact Us";
export const CONTACT_US_EMAIL = "info@getsanta.com";
export const MAIL_TO_LINK = `mailto:${CONTACT_US_EMAIL}`;
export const CONTACT_US_PHONE = "(972) 449-7435";
export const PHONE_TO_LINK = `tel:${CONTACT_US_PHONE.replace(
  /(|)|-|\/s/g,
  ""
)}`;
export const LEGAL_TITLE = "Legal";
export const TERMS_CONDITIONS = "Terms & Conditions";
export const TERMS_CONDITIONS_LINK = "https://getsanta.com/legal/terms";
export const PRIVACY_POLICY = "Privacy Policy";
export const PRIVACY_POLICY_LINK = "https://getsanta.com/legal/privacy";
export const APP_STORE_LINK =
  "itms-apps://apps.apple.com/us/app/santa-the-store-at-your-door/id1568271840";
export const GET_APP = "Get the app";
export const TRY_SERVICE = "Try Santa now";

export const LOCAL_PROFILE_LINK =
  "https://localprofile.com/2021/11/23/the-santa-app-arrives-in-frisco/";
export const D_MAGAZINE_LINK =
  "https://www.dmagazine.com/shopping-fashion/2021/12/santa-personal-shopper-app-delivery/";
export const ABC_SEVEN_LINK =
  "https://www.wfaa.com/article/news/local/new-mobile-shopping-service-santa-in-collin-country-delivers-goods-right-to-your-door-within-minutes-of-ordering/287-ba62760e-7faa-41b4-b675-90b9823797f7";
export const DALLAS_MORNING_NEWS_LINK =
  "https://www.dallasnews.com/business/retail/2021/09/23/santa-a-new-retail-concept-on-wheels-starts-out-first-in-plano-and-frisco/";
export const BOSTON_GLOBE_LINK =
  "https://www.bostonglobe.com/2022/02/23/business/in-person-online-these-retail-concepts-ask-why-not-both/";

export const TESTIMONIALS: Testimonial[] = [
  {
    order: 1,
    name: "Andrea | Roanoke, TX",
    text: "”It has been a life saver, we have used Santa for everything...last minute birthday gifts, baby shower gifts...”",
  },
  {
    order: 2,
    name: "Ryan | Frisco, TX",
    text: "”Santa is great for last minute gift giving, especially for guys like me that tend to forget birthdays sometimes.”",
  },
  {
    order: 3,
    name: "Rachel | Boston, MA",
    text: "”Got to love the Santa!”",
  },
  {
    order: 4,
    name: "Jessica | Dallas, TX",
    text: "”Santa made gifting EASY.”",
  },
  {
    order: 5,
    name: "Monique | The Colony, TX",
    text: "”As a busy mom and wife, having Santa is super helpful for me.”",
  },
];

export const BESTSELLERS: Bestseller[] = [
  {
    order: 1,
    name: "Le Specs - The Prince Sunglasses",
    asset: "galleryLeSpecsPrinceSunglasses",
  },
  {
    order: 2,
    name: "Sunday Citizen Tulum Throw",
    asset: "gallerySundayCitizenTulumThrow",
  },
  {
    order: 3,
    name: "Fellow Carter Everywhere Mug",
    asset: "galleryFellowCarterEverywhereMug",
  },
  {
    order: 4,
    name: "TÖST Non-Alcoholic Refresher",
    asset: "galleryTostNonAlcoholicRefresher",
  },
  {
    order: 5,
    name: "Kinfield S.O.S Rescue Mask",
    asset: "galleryKinfieldSosRescueMask",
  },
  {
    order: 6,
    name: "Alleyoop Multi-Mood Lip Set",
    asset: "galleryAlleyoopMultiMoodLipSet",
  },
  {
    order: 7,
    name: "Legacy Sidecar 5-Piece Bar Tool Set",
    asset: "galleryLegacySidecarBarToolSet",
  },
  {
    order: 8,
    name: "P.F. Candle Co: Black Fig",
    asset: "galleryPFCandleCoBlackFig",
  },
  {
    order: 9,
    name: "Public Goods Organic Ground Coffee",
    asset: "galleryPublicGoodsOrganicGroundCoffee",
  },
  {
    order: 10,
    name: "Tangram SmartRope LED",
    asset: "galleryTangramSmartropeLed",
  },
  {
    order: 11,
    name: "Chance Sebastian Basketball",
    asset: "galleryChanceSebastianBasketball",
  },
  {
    order: 12,
    name: "Eco Kids Finger Paint",
    asset: "galleryEcoKidsFingerPaint",
  },
];
