import React from "react";
import LandingSection from "../components/sections/landing";
import HowItWorks from "../components/sections/howItWorks";
import MeetSanta from "../components/sections/meetSanta";
import CustomersFeedback from "../components/sections/customersFeedback";
import Footer from "../components/sections/footer";
import OurBestsellers from "../components/sections/ourBestsellers";
import MeetSantaBestsellersSeparator from "../components/sections/meetSantaBestsellersSeparator";

// markup
const IndexPage = () => {
  return (
    <>
      <LandingSection />

      <Footer />
    </>
  );
};

export default IndexPage;
