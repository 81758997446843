import React from "react";
import Logo from "../../../assets/logo.svg";
import * as strings from "../../../utils/staticContent";
import "./style.css";

const LandingSection = () => {
  return (
    <div className="flex flex-col items-center px-6 bg-primary">
      <div className="max-w-7xl flex flex-col items-center w-full text-center">
        <Logo className="h-9 w-28 mt-12 mb-40 self-start" />
        <h1 className="text-5xl leading-[50px] mb-12 text-beige font-domaine uppercase ">
          {strings.MAIN_TITLE}
        </h1>
        <p className="text-xl text-beige">
          {strings.SANTA_SERVICE_SHORT_DESCRIPTION}
        </p>
      </div>
    </div>
  );
};

export default LandingSection;
